
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/Flex.css'
import * as services from './api/service'
Vue.config.productionTip = false
import 'swiper/swiper-bundle.min.css'

import Swiper from 'swiper/swiper-bundle.min.js'
import { Navigation, Pagination } from 'swiper'

Swiper.use([Navigation, Pagination])
Vue.prototype.Swiper = Swiper;
Vue.prototype.$services = services;
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
