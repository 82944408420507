

import axios from 'axios';

export let axiosInstance = axios.create({
    // baseURL: 'http://106.52.249.90:9007/'//测试服
    // baseURL: 'http://192.168.1.93:9007/' //fk
    // baseURL: 'https://www.aiyapos.us/epayworld'
    baseURL: '/mp/'
    // baseURL: 'http://3.142.211.37:9007/'
})
// 请求拦截器
axiosInstance.interceptors.request.use(
    (config) => {
        // 请求头
        // let token = Store.getters.token;
        // if (token) {
        //     config.headers.mutualPayAuth = token
        // }
        // window.scrollTo(0,0);
        return config
    },
    (err) => {
        return Promise.reject(err)
    }
)
// 响应拦截器
axiosInstance.interceptors.response.use(
    (response) => {
        return response.data
    },
    (error) => {
        return Promise.reject(error.response) // 返回接口返回的错误信息
    }
)
// export default {
//     axiosInstance,
//     api
// }