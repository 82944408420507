
<template>
  <div id="app">
     <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
}
ul{
  list-style: none;
}
</style>
