
import { axiosInstance as api} from './index'
//获取供应商信息
export const getMpSuppliers = (data)=>api.post(`/MpSupplierServe/getMpSuppliers`,data);

//获取全部分类 post
export const getMpCategorys = (data) => api.post(`/MpCategoryServe/getMpCategorys`,data);

//获取商品 （分页）
export const getMpGoods = (data) => api.post(`/MpGoodsServe/getMpGoodsByFront`,data);

//获取banner （分页）
export const getMpBanners = (data) => api.post(`/MpBannerServe/getMpBanners`,data);